import React from 'react';
import { Container, Card } from 'react-bootstrap';

export default function About() {
  return (
    <Container>
      <Card>
        <Card.Body>
          <Card.Title>About Page</Card.Title>
          <Card.Text>
            This is the about page of our application.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
}
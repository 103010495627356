import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { allPosts } from '../postsDist';
import PostCard from './PostCard';

export default function Home() {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const filteredPosts = Object.entries(allPosts).filter(([key, post]) =>
        post.title.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
    );

    return (
      <div className="text-start">
        <ReactMarkdown>{allPosts["index"]?.mdText}</ReactMarkdown>
        <div className="mb-3">
          <h3>All my posts</h3>
          <label htmlFor="search" className="form-label">Filter by title (optional)</label>
          <input
            type="text"
            className="form-control"
            id="search"
            placeholder="Enter post title"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        {filteredPosts.map(([key, post], index) => (
          <PostCard key={index} post={post} urlSuffix={`/${key}`} />
        ))}
      </div>
    );
}
import React from 'react';
import { useParams } from 'react-router-dom';
import { allPosts } from './postsDist';
import ReactMarkdown from 'react-markdown';

const Post: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  
  console.log("NWM: postId", postId);

  if (postId && allPosts[postId as keyof typeof allPosts]) {
    const { mdText, createdOn } = allPosts[postId as keyof typeof allPosts];
    return (
      <div className="text-start">
        <ReactMarkdown>{mdText}</ReactMarkdown>
        <p className="text-muted mt-2" style={{ fontSize: '1em' }}>
          {new Date(createdOn).toLocaleDateString()}
        </p>
      </div>
    );
  }

  if (postId === "" || postId === undefined) {
    return (
      <div className="text-start">
        <ReactMarkdown>{allPosts["index" as keyof typeof allPosts].mdText}</ReactMarkdown>
      </div>
    );
  }

  return <h1>Post not found</h1>;
};

export default Post;